<template>
  <div>
    <header-slot>
      <template #actions>
        <b-row class="justify-content-end mr-1">
          <b-button
            variant="outline-primary"
            @click="openAddModalRelationship"
          >
            <b-icon icon="plus" />
            Add</b-button>
        </b-row>
      </template>
    </header-slot>
    <b-nav
      card-header
      pills
      class="m-0"
    >
      <b-nav-item
        :to="{ name: 'administration-settings' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >Settings</b-nav-item>
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="key" />
    </b-card>
    <modal-add-relationship
      v-if="modalRelationship"
      @refresh="key++"
      @closeModal="closeModalRelationship"
    />
  </div>
</template>

<script>
import ModalAddRelationship from '@/views/administration/views/settings/components/ModalAddRelationship.vue'

export default {
  components: {
    ModalAddRelationship,
  },
  data() {
    return {
      modalRelationship: false,
      key: 0,
    }
  },
  methods: {
    openAddModalRelationship() {
      this.modalRelationship = true
    },
    closeModalRelationship() {
      this.modalRelationship = false
    },
  },
}
</script>

  <style>
</style>
